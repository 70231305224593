<template>
  <div class="content-page">
    <template v-if="data?.[0]?.sections">
      <render-sections :content="data[0].sections" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { ContentPage } from "@shop-storefront/utils";
import RenderSections from "~/components/sections/RenderSections/RenderSections.vue";
import { onEntryChange } from "~/plugins/contentstack";
import { ContentstackPageParams } from "~/plugins/contentstack/contentstack.types";
import ContentType from "~/utils/contentstack/constants/content-types.constants";
import { contentPageReferences } from "~/utils/contentstack/constants/references.constants";
import { resolvePageSeo } from "~/utils/contentstack/resolvers/gloabal-fields/page-seo/page-seo.resolvers";

const { $contentstack } = useNuxtApp();
const {
  params: { slug },
  fullPath,
  query,
} = useRoute();

const data: Ref<ContentPage[] | undefined> = ref(undefined);
const getData = async () => {
  const url = Array.isArray(slug) ? slug[slug.length - 1] : slug;

  const content = await $contentstack.getEntries<ContentPage>({
    type: ContentType.contentPage,
    includeReference: contentPageReferences as string[],
    customQuery: {
      [ContentstackPageParams.slug]: url,
    },
  });

  data.value = content;
};

onMounted(() => {
  if (query.live_preview) {
    onEntryChange(async () => {
      getData();
    });
  }
});

await getData();

if (!data.value?.length) {
  const { show404Page } = useErrorPage();
  show404Page(ErrorPageType.content, fullPath);
}

if (data?.value?.[0]?.seo) {
  useHead(resolvePageSeo(data.value[0].seo));
}
</script>
